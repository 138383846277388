import React from "react";


const AddIcon =(props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 465.44 466.18"
      version="1.1"
      viewBox="0 0 465.44 466.18"
      xmlSpace="preserve"
      width="18px"
      height="18px"
      fill={props.fill}
    >
      <path d="M217.1 466.18c-3.11-1.15-6.37-1.99-9.29-3.49-10.16-5.22-16.03-13.58-17.3-25-.28-2.55-.31-5.14-.31-7.72-.02-49.28-.01-98.56-.01-147.84v-6.49h-6.16c-50.03 0-100.06.02-150.09-.01-16.34-.01-28.32-8.22-32.76-22.32-.32-1.01-.73-2.05-.74-3.08-.05-11.33-1.11-22.79.26-33.96 1.68-13.74 13.66-23.7 27.41-25.06 2.41-.24 4.84-.29 7.26-.29 49.42-.02 98.85-.01 148.27-.01h6.54v-6.11c0-50.04.04-100.08-.03-150.12-.02-13.99 5.41-24.69 18.09-31.11 9.27-4.7 39.31-4.74 48.57-.08 10.33 5.2 16.44 13.6 17.76 25.22.29 2.55.33 5.14.33 7.72.02 49.28.01 98.56.01 147.84v6.64h6.02c48.52 0 97.04.38 145.54-.19 19.05-.23 33.62 6.42 38.95 27.09v30.93c-5.38 20.82-20.11 27.3-38.94 27.09-48.49-.56-96.99-.19-145.49-.19h-6.08v6.57c0 49.72-.05 99.43.04 149.15.02 9.82-2.46 18.51-9.51 25.65-4.86 4.93-11.03 7.24-17.44 9.18-10.28-.01-20.59-.01-30.9-.01z"></path>
    </svg>
  );
}
AddIcon.defaultProps = {
  fill: "#0F183F",
};
export default AddIcon;

