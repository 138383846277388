import {
  all,
  takeEvery,
  put,
  fork,
  select,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";
import i18n from 'i18next'; 
import {getToastConfig} from "src/utils/index";

export function* GetWalletsList() {
  yield takeEvery(types.GET_WALLETS_LIST, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "label": data !== undefined ? data.title : "",
      "page": data.page,
      "pageSize": data.pageSize
    };
    try {
      const response = yield Api.Post(Endpoints.GetWalletList, body, true);

      if (response) {
        yield put({
          type: types.GET_WALLETS_LIST_SUCCESS,
          data: { data: response, count: response.wallets.totalItemsCount , skip : data.skip ,page:data.page },
        });
      } else
        yield put({
          type: types.GET_WALLETS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_WALLETS_LIST_FAIL,
      });
    }
  });
}

export function* AddWallet() {
  yield takeEvery(types.ADD_WALLET, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "blockchainId": data.fields.blockchainId,
      "networkId": data.fields.networkId,
      "exportable": data.fields.exportable === "on" ? true : false,
      "label": data.fields.label,
      "type": Number(data.fields.type),
      "Mnemonic": data.fields.Mnemonic,
      "privateKeyHex": data.fields.privateKeyHex,
      "chainCodeHex": data.fields.chainCodeHex,
      "kmsKeyId": data.fields.kmsKeyId,
      "parenWalletId": data.fields.parenWalletId,
    }
    let params = { "xapikey": data.fields.apiKey }
    try {
      const response = yield Api.Post(Endpoints.AddWallet, body, true, "wallet", params);
      if (response) {
        yield put({
          type: types.ADD_WALLET_SUCCESS,
          data: response
        });
        data?.callBack();
        toast.success(i18n.t("DoneSuccessfully"), getToastConfig(direction));

      } else
        yield put({
          type: types.ADD_WALLET_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.ADD_WALLET_FAIL,
      });
    }
  });
}

export function* GetWalletById() {
  yield takeEvery(types.GET_WALLET_BY_ID, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    try {
      const response = yield Api.Get(Endpoints.GetWalletById + `?UserNetworkEndpointId=${data.id}`, true);
      if (response) {
        yield put({
          type: types.GET_WALLET_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_WALLET_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {

      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_WALLET_BY_ID_FAIL,
      });
    }
  });
}

export function* GetParentWalletList() {
  yield takeEvery(types.GET_PARENT_WALLETS_LIST, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body ={
      "blockchainId": data.blockchainId,
      "networkId":data.networkId
    }
    try {
      const response = yield Api.Post(Endpoints.GetParentWalletList,body, true);

      if (response) {
        yield put({
          type: types.GET_PARENT_WALLETS_LIST_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.GET_PARENT_WALLETS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_PARENT_WALLETS_LIST_FAIL,
      });
    }
  });
}
export default function* WalletSagas() {
  yield all(
    [
      fork(GetWalletsList),
      fork(AddWallet),
      fork(GetWalletById),
      fork(GetParentWalletList)
    ]);
}