import React, { useRef, useState } from "react";
import { FooterWrapper } from "./styles";

const Footer = (props) => {
    return (
        <FooterWrapper>
            Powered by
            <a href="https://polaristech.ir/">Polareum  </a>
        </FooterWrapper>
    )
}
export default Footer