import { combineReducers } from 'redux';
import App from './App/Reducer';
import Login from './Login/Reducer';
import Endpoint from './Endpoint/Reducer';
import User from './User/Reducer';
import Dashboard from './dashboard/Reducer';
import Wallet from './wallet/Reducer';
import Payment from './Payment/Reducer';
import HyperledgerFabric from './HyperledgerFabric/Reducer';
import Survey from './Survey/Reducer';


export default combineReducers({
  App,
  Login,
  Endpoint,
  User,
  Dashboard,
  Wallet,
  Payment,
  HyperledgerFabric,
  Survey
});