import styled from 'styled-components';
import Col from "src/kit/Column";

export const RegisterCompleteFormWrapper = styled(Col)`
    height :100vh;
    background-repeat:no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
    background-image :  url(${(props) => props.src});
    position:relative;

`;
