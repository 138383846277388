import React from "react";
import { SurveyCard } from "../style";
import { Grid, Box } from "@mui/material";
import crtl from "../survey.controller";
import Button from "src/kit/Button";

const Survey = () => {
    const { participateInSurvey,
    } = crtl();

    const list = [
        { id: 0, title: "حدیثه عسگری" },
        { id: 1, title: "نیلوفر نادریان" },
        { id: 2, title: "قرائی" },

    ]

    const change = (e) => {
        let value = e.target.checked;
        let name = e.target.name;
    }

    return (
        <Grid container display="flex" justifyContent="space-between">
            <Grid container>
                <Box>
                    افراد مورد نظر خود را انتخاب کنید. (حداکثر 3 نفر)
                </Box>
                {list.map((each, index) => {
                    return (
                        <Grid item xs={12} md={12} p={2} key={index}>
                            <input type="checkbox" id={each.id} name={each.id} onChange={(e) => { change(e) }} />
                            {each.title}
                        </Grid>
                    )
                })}

            </Grid>
            <Grid container xs={6} p={1}>
                <Grid item xs={6} p={1} >
                    <Button
                        // onConfirm={() => { change(false) }}
                        text="بله"
                    >
                    </Button>
                </Grid>
                <Grid item xs={6} p={1} id="reject_btn" >
                    <Button
                        // onConfirm={() => { change(false) }}
                        text="خیر"
                    >
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Survey