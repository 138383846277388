import {
  all,
  takeEvery,
  put,
  fork,
  takeLatest,
  select
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";
import i18n from 'i18next'; 
import {getToastConfig} from "src/utils/index"

export function* login() {

  yield takeLatest(types.LOGIN, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    try {
      let body = {
        email: data.values.email,
        password: data.values.password,
        rememberMe: data.values.rememberMe,
      };
      const response = yield Api.Post(Endpoints.Login, body, false);
      // data.action.resetForm();
      // data.action.setSubmitting(false);
      // debugger
      toast.success(i18n.t("loginSuccess"), getToastConfig(direction));
      yield put({
        type: types.LOGIN_SUCCESS,
        data: response
      });

    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title,getToastConfig(direction));
      // data.action.resetForm();
      yield put({
        type: types.LOGIN_FAIL,
      });
    }
  });
}

export function* Logout() {
  yield takeEvery(types.LOGOUT, function* ({ data }) {
    try {
      const response = yield Api.Post(Endpoints.Logout, {});
      yield put({
        type: types.LOGOUT_SUCCESS,
      });
    } catch (e) {
      yield put({
        type: types.LOGOUT_FAIL,
      });
    }
  });
}

export function* Register() {
  yield takeEvery(types.REGISTER, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "email": data.values.email,
      "orgName": data.values.orgName,
      "password": data.values.password,
      "confirmPassword": data.values.confirmPassword
    }
    try {
      const response = yield Api.Post(Endpoints.Register, body, true);

      if (response) {
        toast.success(i18n.t("registerSuccess"), getToastConfig(direction));
        setTimeout(() => {
          data?.callBack();
        }, 2000);
      } else
        yield put({
          type: types.REGISTER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.REGISTER_FAIL,
      });
    }
  });
}


export function* ForgerPassword() {
  yield takeEvery(types.FORGET_PASSWORD, function* ({ data }) {

    let body = data.value.email
    const direction = yield select((state) => state.App.direction);
    try {
      const response = yield Api.Post(Endpoints.ForgerPassword, body, true);

      if (response) {
        toast.success(i18n.t("forgetPasswordSuccess"), getToastConfig(direction));
        yield put({
          type: types.FORGET_PASSWORD_SUCCESS,
          data: { data: response},
        });
      } else

        yield put({
          type: types.FORGET_PASSWORD_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title,getToastConfig(direction));
      yield put({
        type: types.FORGET_PASSWORD_FAIL,
      });
    }
  });
}

export function* ResetPassword() {
  yield takeEvery(types.RESET_PASSWORD, function* ({ data }) {

    let body = {
      email: data.values.email,
      password: data.values.newPassword,
      confirmPassword: data.values.confirmPassword
    }
    const direction = yield select((state) => state.App.direction);
    try {
      const response = yield Api.Post(Endpoints.ResetPassword + `?resetToken=${data.resetToken}`, body, false);

      if (response) {
        data?.callBack();
        toast.success(i18n.t("resetPasswordSuccess"), getToastConfig(direction));
        yield put({
          type: types.RESET_PASSWORD_SUCCESS,
          data: { data: response},
        });
      } else

        yield put({
          type: types.RESET_PASSWORD_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {

      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title,getToastConfig(direction));
      yield put({
        type: types.RESET_PASSWORD_FAIL,
      });
    }
  });
}

export function* ConfirmEmail() {
  yield takeEvery(types.CONFIRM_EMAIL, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    try {
      const response = yield Api.Get(Endpoints.ConfirmEmail + `?userId=${data.userId}&code=${data.code}`, false);

      if (response) {
        toast.success(i18n.t("confirmEmailSuccess"), getToastConfig(direction));
        yield put({
          type: types.CONFIRM_EMAIL_SUCCESS,
          data: { data: response},
        });
      } else
        yield put({
          type: types.CONFIRM_EMAIL_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {

      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title,getToastConfig(direction));
      yield put({
        type: types.CONFIRM_EMAIL_FAIL,
      });
    }
  });
}

export default function* LoginSagas() {
  yield all(
    [
      fork(login),
      fork(Logout),
      fork(Register),
      fork(ForgerPassword),
      fork(ResetPassword),
      fork(ConfirmEmail)
    ]);
}