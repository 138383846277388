import styled from "styled-components";
import Row from "../../kit/Row";
import Col from "src/kit/Column";

export const DashboardWrapper = styled(Row)`
  width: 100%;
  padding :20px;
  .Select-menu-outer {
      text-align : ${(props) => props.direction === "rtl" ? "right" : "left"};
      padding-right : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
      padding-left : ${(props) => props.direction === "ltr" ? "10px" : "unset"};
  }
`;
export const CardContainer = styled(Col)`
display: inline-block;
padding: 20px;
border: dashed 2px #000;
border-style: dashed;
// border-image-source: url("https://i.stack.imgur.com/wLdVc.png");
border-image-slice: 1;
border-image-repeat: round;
border-image-source: url("https://i.stack.imgur.com/LKclP.png");
box-sizing: border-box;
height:300px;
width: 100%;
display: flex;
.box-title{
  font-family: ${(props) => (props.theme.fontBold)};
  font-size: 1.15rem;
}
img{
  width:70px;
}

`;

export const PieChartContainer = styled(Col)`
  width : 185px;
  height : 185px;
  border: 5px solid  ${(props) => props.theme.primaryDark};
  border-radius :50%;
  padding :10px;
  
 .box {direction :${(props) => props.direction === "ltr" ? "rtl" : "ltr"} !important;}

 .gray-text {
    color : #ccc;
    font-size : ${(props) => (props.theme.fontSizeXXl)};
    font-family: ${(props) => (props.theme.fontBold)};
 }
 .orange-text{
    color : ${(props) => props.theme.primary};
    font-size : ${(props) => (props.theme.fontSizeXXl)};
    font-family: ${(props) => (props.theme.fontBold)};
 }
 .box{
    width: 100%;
    justify-content: center;
    display : flex;
 }
 .box:first-child {
  border-bottom : 1px solid #ccc;
 }
 .box-label{
  font-size : ${(props) => (props.theme.fontSizeMd)};
  display: flex;
    align-items: center;
    padding :0 5px;
 }
`;
export const ChartContainer = styled(Col)`
  width: 100%;
  min-height :400px;
  background-color:  ${(props) => (props.theme.primaryLight)} ;
  box-sizing: border-box;
  box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
  margin-bottom:20px;
  display : flex;
  align-items : center;
`;