import { string } from "../rules";
import * as Yup from "yup";

export const VerifyValidationOrderer = Yup.object().shape({

    ordererName: string("ordererName", true),
    ordererType: string("ordererType", true),
    ordererPort: string("ordererPort", true),

});
