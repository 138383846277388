import React from "react";

function Icon({pathFill ="AFAEAE"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 20 20"
    >
      <path
        fill={pathFill}
        strokeWidth="0"
        d="M12.965.096a2.75 2.75 0 00-.461.033c-.601.093-1.105.31-2.088.478C9.513.762 8.9.538 8.135.344 7.369.149 6.44.025 5.293.544a.5.5 0 00-.209.733l1.773 2.659c-.377.359-1.47 1.423-2.757 3.138C2.57 9.114 1 11.8 1 14.5c0 2.65 1.309 4.164 3.07 4.842C5.832 20.019 8 20 10 20c2 0 4.168.02 5.93-.658C17.69 18.664 19 17.15 19 14.5c0-2.7-1.57-5.386-3.1-7.426-1.286-1.715-2.38-2.78-2.757-3.138l1.773-2.659a.5.5 0 00-.219-.736C13.98.233 13.442.102 12.965.096zm-.309 1.021c.267-.04.675.12 1.024.213L12.232 3.5H7.768L6.305 1.305a3.133 3.133 0 011.584.01c.703.178 1.551.475 2.695.279 1.063-.183 1.644-.41 2.072-.477zM7.707 4.5h4.586c.187.175 1.441 1.356 2.807 3.176C16.57 9.636 18 12.2 18 14.5c0 2.35-.941 3.336-2.43 3.908C14.082 18.981 12 19 10 19c-2 0-4.082-.02-5.57-.592C2.94 17.836 2 16.85 2 14.5c0-2.3 1.43-4.864 2.9-6.824C6.266 5.856 7.52 4.675 7.707 4.5zM10.479 9l-.393.025-.387.067-.375.111-.363.152-.342.192-.318.228-.291.264-.26.295-.225.32-.19.346h-.862L6 12h1.035l-.033.334v.332l.033.334h-.562L6 14h1.336l.19.346.224.32.26.295.29.264.32.228.34.193.364.15.375.112.387.07.393.022.392-.018.387-.064.377-.107.365-.147-.43-.904-.32.123-.33.084-.34.033-.344-.014-.338-.058-.324-.104-.308-.15-.287-.188-.256-.226-.225-.26h1.79l.474-1H8.049l-.043-.334v-.332L8.049 12h2.69l.472-1H8.498l.225-.26.256-.226.287-.188.308-.15.324-.104.338-.058.344-.014.34.033.33.084.32.123.43-.902-.365-.149-.377-.107-.387-.062L10.48 9z"
      ></path>
    </svg>
  );
}

export default Icon;
