import { string ,boolean } from "../rules";
import * as Yup from "yup";

export const VerifyValidationRegister = Yup.object().shape({

    // email: email("email", true),
    confirmPassword: string("confirmPassword", true),
    password: string("password", true),
    rule: boolean("rule", true),


});
