import React , { useEffect }from "react";
import EndpointView from "src/views/Endpoint";
import MainLayout from "src/shared/MainLayout";
import EndpointActions from "src/redux/Endpoint/Actions";
import { useDispatch,useSelector } from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";

const Dashboard = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);
    useEffect(() => {
        dispatch(EndpointActions.getEndpointsList({page:1,pageSize :5 ,skip:0}));
        dispatch(EndpointActions.getEndpoints());
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }
    },[])
    return (
        <MainLayout>
            <EndpointView />
        </MainLayout>
    )
}
export default Dashboard