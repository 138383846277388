

import React, { useState } from "react";
import HyperLedgerFabricsActions from "src/redux/HyperledgerFabric/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, CardContainer, AddContainer } from "src/shared/generalStyles";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import HyperLedgerFabricTableConfig from "./hyperFabricTableConfig"
import Table from "src/shared/table";


const HyperLedgerFabric = () => {

    const dispatch = useDispatch();

    const HyperLedgerFabricState = useSelector((state) => state.HyperledgerFabric);
    const { takeHyperLedgerFabric, skipHyperLedgerFabric, HyperLedgerFabricLoading, count, mode, hyperLedgerFabricsList } = HyperLedgerFabricState;

    const defaultfields = {
        id: "",
        title: "",
        network: "",
        blockchain: "",
        HyperLedgerFabricType: ""
    };
    const [fields, setFields] = useState(defaultfields);
    const [filterState, setFilterState] = useState({
        title: "",
    });


    //////////////////////////////////////
    const pageHandler = (res) => {
        dispatch(
            HyperLedgerFabricsActions.getHyperLedgerList({
                skip: res,
                take: takeHyperLedgerFabric,
                is_active: filterState.is_active,
                menu_position: filterState.menu_position,
                title: filterState.title,
                pageSize: takeHyperLedgerFabric,
                page: (res + takeHyperLedgerFabric) / takeHyperLedgerFabric
            })
        );
    }


    ////////////////////////////////

    return (
        <>
            <CardContainer>
                <>
                    <Grid container>
                        <Grid item md={9} className="filter-box">

                        </Grid>
                        <Grid item md={3} >
                            <Link to={{ pathname: '/dedicatedNetwork/hyperledgerFabric/add', state: {} }} >
                            <AddContainer>
                                    <span >
                                        Add Fabric
                                    </span>
                                </AddContainer>
                            </Link>
                        </Grid>
                    </Grid>

                </>
                <TableWrapper height="calc(100% - 60px)" id="HyperLedgerFabric-table">
                    <Table
                        tblConfig={HyperLedgerFabricTableConfig()}
                        tblData={hyperLedgerFabricsList}
                        showPaging={true}
                        pageHandler={(res) => {
                            pageHandler(res);
                        }}
                        skip={skipHyperLedgerFabric}
                        take={takeHyperLedgerFabric}
                        count={count}
                        loading={HyperLedgerFabricLoading}
                    />
                </TableWrapper>
            </CardContainer>
        </>
    )
}

export default HyperLedgerFabric;