import React from "react";
import { CardContainer } from "src/shared/generalStyles";
import { SurveyContainer } from "./style";
import crtl from "./survey.controller";
import ConfirmationIcon from "./components/confirmationModal";
import RejectModal from "./components/rejectModal";
import SurveyList from "./components/surveyList";
import Survey from "./components/Survey";

import { useSelector } from "react-redux";

const SurveyView = () => {
  const {
    onCloseConfirmationModalHandler,
    onCloseRejectModalHandler } = crtl();
  const surveyState = useSelector((state) => state.Survey);
  const { showConfirmationModal,
    showRejectModal } = surveyState;


  return (
    <CardContainer>
      <SurveyContainer>
        <SurveyList />
        <ConfirmationIcon
          open={showConfirmationModal}
          text="آیا برای مشارکت در نظرسنجی مطمئن هستید؟"
          onClose={onCloseConfirmationModalHandler}
        />
        <RejectModal
          open={showRejectModal}
          text="برای شرکت در نظرسنجی کیف پول خود را شارژ کنید"
          onClose={onCloseRejectModalHandler}
        />
      </SurveyContainer>
    </CardContainer>
  )
}
export default SurveyView