import types from "./Types";
import RecordController from "src/controllers/RecordController";
import { prepareSelectData } from "src/utils/index"

const initState = {
  showConfirmationModal: false,
  showRejectModal: false,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.PARTICIPATE_IN_SURVEY:
      if (action.data === true) {
        return {
          ...state,
          showConfirmationModal: true,
        };
      }
      else {
        return {
          ...state,
          showRejectModal: true,
        };
      }
      case types.SET_SHOW_CONFIRMATION_MODAL:
        return {
          ...state,
          showConfirmationModal: action.data.visible
        };
        case types.SET_SHOW_REJECT_MODAL:
          return {
            ...state,
            showRejectModal: action.data.visible
          };
    default:
      return state;
  }
}