import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const SurveyContainer = styled(Column)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100% - 50px);
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.10);
    margin-bottom :20px;
    justify-content: space-between;
    overflow-y: auto;
`;
export const SurveyCard = styled(Column)`
    height: 200px;
    border: 1px solid #FFCF81;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
`;
