import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SurveyActions from "src/redux/Survey/Actions";

export default () => {
    const dispatch = useDispatch();
 

    ////////////////////////////////////////////
    const participateInSurvey = (show) => {
        dispatch(SurveyActions.participateInSurvey(show));
    };
    const onCloseConfirmationModalHandler = (show) => {
         dispatch(SurveyActions.setShowConfirmationModal({visible :show}));
    };
    const onCloseRejectModalHandler = (show) => {
         dispatch(SurveyActions.setShowRejectModal({visible :show}));
         window.location.href = "/payment/purchase"
    };

    return {
        participateInSurvey,
        onCloseConfirmationModalHandler,
        onCloseRejectModalHandler
    }
}
