import React from "react";
import { SurveyCard } from "../style";
import { Grid, Box } from "@mui/material";
import crtl from "../survey.controller";

const SurveyList = () => {
  const { participateInSurvey,
  } = crtl();

  const list = [
    { id: 0, title: "1" , show: true},
    { id: 1, title: "1" , show: false },
  ]

  return (

        <Grid container>
          {list.map((each, index) => {
            return (
              <Grid item xs={12} md={6} p={2} key={index}>
                <SurveyCard>
                  <Grid item xs={10} p={2} md={8} lg={6}>
                    <button
                      color="primary"
                      size="lg"
                      onClick={() => participateInSurvey(each.show)}
                      className="ml-1 btn-form"
                    >
                      <Box className="color-white" p={2}>شرکت در نظرسنجی</Box>
                    </button>
                  </Grid>
                </SurveyCard>
              </Grid>
            )
          })}
        </Grid>
  )
}
export default SurveyList