
import React from "react";
import { Box, Grid, } from "@mui/material";
import DeleteIcon from "src/assets/icons/DeleteIcon";
import filterCrtl from "../../hyperledger.controllers";
import { FormInput } from "src/kit/Form";
import { useTranslation } from 'react-i18next';

const OrgSetting = ({

}) => {
    const { handleAddForm,
        handleOrgChange,
        handleDeleteForm,
        handleSubmitOrganization,
        errors,
        organizations,
        direction
     } = filterCrtl();
        const { t } = useTranslation();


    return (
        <>
            <Grid container py={1} m={2}>
                <Grid xs={2} item display="flex" p={1} className="add-container clickable" onClick={handleAddForm}>
                    <Box display="flex" alignItems="center" className={"filter-title"}>
                      {t("addOrganization")}
                    </Box>
                </Grid>
                <Grid xs={12} item >
                    <Grid container >
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("organization_name")}</Box>
                        </Grid>
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("organizationMSPID")}</Box>
                        </Grid>
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("peer_number_in_the_organization")}</Box>
                        </Grid>
                        <Grid xs={1} item p={1}>

                        </Grid>
                    </Grid>
                    {organizations.map((form, index) => (
                        <Grid container key={index}>
                            <Grid xs={3.5} item p={1}>
                                <FormInput
                                    value={form.orgName}
                                    name="orgName"
                                    id="orgName"
                                    placeholder={t("organization_name")}
                                    onChange={(e) => handleOrgChange(index, 'orgName', e.target.value)}
                                    className={
                                        "custom_input" + (!!errors[index]?.orgName ? " warning-border" : "")
                                    }
                                />
                                {!!errors[index]?.orgName ? (
                                    <div className="text-alert">{errors[index]?.orgName}</div>
                                ) : <div className="text-alert"></div>}
                            </Grid>
                            <Grid xs={3.5} item p={1}>
                                <FormInput
                                    value={form.MSPId}
                                    name="MSPId"
                                    id="MSPId"
                                    placeholder={t("organizationMSPID")}
                                    onChange={(e) => handleOrgChange(index, 'MSPId', e.target.value)}
                                    className={
                                        "custom_input" + (!!errors[index]?.MSPId ? " warning-border" : "")
                                    }
                                />
                                {!!errors[index]?.MSPId ? (
                                    <div className="text-alert">{errors[index]?.MSPId}</div>
                                ) : <div className="text-alert"></div>}
                            </Grid>
                            <Grid xs={3.5} item p={1}>
                                <FormInput
                                    value={form.peerNum}
                                    name="peerNum"
                                    id="peerNum"
                                    placeholder={t("peer_number_in_the_organization")}
                                    onChange={(e) => handleOrgChange(index, 'peerNum', e.target.value)}
                                    className={
                                        "custom_input" + (!!errors[index]?.peerNum ? " warning-border" : "")
                                    }
                                />
                                {!!errors[index]?.peerNum ? (
                                    <div className="text-alert">{errors[index]?.peerNum}</div>
                                ) : <div className="text-alert"></div>}
                            </Grid>
                            <Grid xs={1} item p={1} display="flex" justifyContent="center" alignItems="center">
                                <span className="clickable" onClick={() => handleDeleteForm(index)}>
                                    <DeleteIcon />
                                </span>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container display="flex" justifyContent="end">
                        <Grid item xs={4} p={2} md={4} lg={2}>
                            <button
                                color="primary"
                                size="lg"
                                onClick={handleSubmitOrganization}
                                className="ml-1 btn-form"
                            >
                                <Box className="color-white" p={2}>{t("submit")}</Box>
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );


}
export default OrgSetting;