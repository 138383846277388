import React , { useEffect }from "react";
import SurveyView from "src/views/survey";
import MainLayout from "src/shared/MainLayout";
import { useDispatch , useSelector} from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";

const Survey = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);
    useEffect(() => {
  
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }

    },[])
    return (
        <MainLayout>
            <SurveyView />
        </MainLayout>
    )
}
export default Survey