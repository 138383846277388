import React, {  useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "src/assets/icons/CloseIcon";
import { FormContainer} from './styles';
import EditIcon from "src/assets/icons/EditIcon";
import UploadImage from "src/shared/uploadImage"
import UserActions from "src/redux/User/Actions";
import { useTranslation } from 'react-i18next';



const ProfileModal = ({
    open,
    onClose,
    title,
    direction
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        direction :direction
    };

    const userState = useSelector((state) => state.User);
    const { userInfo } = userState;
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >

            <Box sx={style}>
                <Grid item container xs={12} id="modal_title" p={2}>
                    <Grid item xs={11} display="flex" justifyContent="start">
                        {title}
                    </Grid>
                    <Grid item xs={1} onClick={() => onClose(false)} className="clickable">
                        <CloseIcon />
                    </Grid>
                </Grid>
                <FormContainer direction={direction}>

                    <Formik
                        initialValues={{
                            email: userInfo.email,
                            password: "",
                            name: userInfo.orgName
                        }}
                        onSubmit={(values, action) => {
                            dispatch(UserActions.updateProfileInfo({ values: values, logo: selectedImage || userInfo.logo }));
                        }}
                    >
                        {(formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <UploadImage
                                                selectedImage={selectedImage}
                                                setSelectedImage={setSelectedImage}
                                                userInfo={userInfo}
                                                setSelectedFile={setSelectedFile}
                                                 />
                                        </Grid>
                                        <Grid item xs={12} p={2}>
                                            <Box class="input-container">
                                            <span class="icon"><EditIcon /></span>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.name && formik.touched.name ? " warning-border" : "")
                                                }
                                                value={formik.values.name}
                                                id="name"
                                                name="name"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                                placeholder=""
                                            />
                                            <span class="placeholder-text">{t("organization")}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} px={2} >
                                            <Box class="input-container">
                                            <span class="icon"><EditIcon /></span>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                                }
                                                value={formik.values.email}
                                                id="email"
                                                name="email"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                                placeholder=""
                                                disabled
                                            />
                                            <span class="placeholder-text">{t("email")}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} p={2}>
                                            <Button
                                                onClick={() => formik.handleSubmit()}
                                                text={t("save")}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    </Formik>
                </FormContainer>
            </Box>
        </Modal>
    )
}
export default ProfileModal;