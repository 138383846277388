

import React, { useEffect } from "react";
import HyperLedgerFabricsActions from "src/redux/HyperledgerFabric/Actions";
import { useDispatch, useSelector } from "react-redux";
import { CardContainer } from "src/shared/generalStyles";
import { Box, Grid } from "@mui/material";
import { DetailContainer, OrgInfo } from "./style";
import { useTranslation } from 'react-i18next';


const HyperLedgerFabricDetail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const HyperLedgerFabricState = useSelector((state) => state.HyperledgerFabric);
    const { hyperLedgerFabricsDetail } = HyperLedgerFabricState;

    useEffect(() => {
        dispatch(HyperLedgerFabricsActions.getHyperLedgerDetails({ id: props.id }));
    }, []);
    ////////////////////////////////

    return (

        <CardContainer>
            <DetailContainer>
                <Grid container>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>{t("network_Name")}:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.name}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>{t("ordererNodeName")}:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.name}
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>{t("ordererNodePort")}:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.port}
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>{t("ordererType")}:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.type}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box className="text_bold" p={1}>
                    {t("organizations")}:
                    </Box>
                    {hyperLedgerFabricsDetail?.organizations?.map((_, index) => {
                        return (
                            <OrgInfo>
                                <Grid container>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="center" px={1}> {t("organization_name")} :</Box>
                                        <Box className="text_bold">
                                            {_.name}
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="flex-end" px={1}>MspId :</Box>
                                        <Box className="text_bold">
                                            {_.mspId}
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="flex-end" px={1}>  {t("peer_number_in_the_organization")} :</Box>
                                        <Box className="text_bold">
                                            {_.peers.length}
                                        </Box>
                                    </Grid>
                                    <Grid container item md={12} pt={2}>
                                        <Box className="text_bold" p={1}>
                                           {t("peers")}:
                                        </Box>
                                        {_.peers.map((peer, ind) => {
                                            return (
                                                <Grid item md={12} display="flex" px={2}>
                                                    <Box>{ind + 1} -</Box>
                                                    <Box display="flex" alignItems="center" px={1}>{t("peerName")} : {peer.name}</Box>
                                                    <Box display="flex" alignItems="center" px={1}>{t("peerPort")} : {peer.name}</Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                </Grid>
                            </OrgInfo>
                        )
                    })}
                </Grid>

                <Grid container>
                    <Box className="text_bold" p={1}>
                        {t("channels")}:
                    </Box>
                    {hyperLedgerFabricsDetail?.channels?.map((_, index) => {
                        return (
                            <OrgInfo>
                                <Grid container>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="center" px={1}> {t("ChannelName")} :</Box>
                                        <Box className="text_bold">
                                            {_.name}
                                        </Box>
                                    </Grid>

                                    <Grid container item md={12} pt={2}>
                                        <Box className="text_bold" p={1}>
                                           {t("peers")}:
                                        </Box>
                                        {_.peers.map((peer, ind) => {
                                            return (
                                                <Grid item md={12} display="flex" px={2}>
                                                    <Box>{ind + 1} -</Box>
                                                    <Box display="flex" alignItems="center" px={1}>{t("peerName")} : {peer.name}</Box>
                                                    <Box display="flex" alignItems="center" px={1}>{t("peerPort")} : {peer.name}</Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </OrgInfo>
                        )
                    })}
                </Grid>
            </DetailContainer>
        </CardContainer >
    )
}

export default HyperLedgerFabricDetail;