import {
  all,
  takeEvery,
  put,
  fork,
  select,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";
import moment from "moment-jalaali";
import i18n from 'i18next'; 
import {getToastConfig} from "src/utils/index"

export function* GetCu() {
  yield takeEvery(types.GET_CU, function* ({ data }) {
    let response = [];
    const direction = yield select((state) => state.App.direction);
    try {
      response = yield Api.Get(Endpoints.GetCu + `?price=${data.value}`, true);
      if (response || response === 0) {
        yield put({
          type: types.GET_CU_SUCCESS,
          data: { data: response, unit: data.value },

        });
      } else
        yield put({
          type: types.GET_CU_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_CU_FAIL,
      });
    }
  });
}


export function* GetPriceList() {
  yield takeEvery(types.GET_PRICE_LIST, function* ({ data }) {
    let response = [];
    const direction = yield select((state) => state.App.direction);
    try {
      response = yield Api.Get(Endpoints.GetPriceList, true);
      if (response) {
        yield put({
          type: types.GET_PRICE_LIST_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.GET_PRICE_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_PRICE_LIST_FAIL,
      });
    }
  });
}


export function* AddComputationUnit() {

  yield takeEvery(types.ADD_COMPUTATION_UNIT, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "computationUnitCount": data.computationUnitCount,
      "price": data.price

    }
    try {
      const response = yield Api.Post(Endpoints.AddComputationUnit, body, true);
      if (response) {

        yield put({
          type: types.ADD_COMPUTATION_UNIT_SUCCESS,
          data: response
        });

      } else
        yield put({
          type: types.ADD_COMPUTATION_UNIT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.ADD_COMPUTATION_UNIT_FAIL,
      });
    }
  });
}

export function* GetPaymentList() {
  yield takeEvery(types.GET_PAYMENT_LIST, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "startDateFrom":data.startDateFrom !==null ? moment(data.startDateFrom, 'jYYYY-jMM-jDD').format("YYYY-MM-DDTHH:mm:ss.SSSS") : null,
      "startDateTo":data.startDateTo !==null ? moment(data.startDateTo, 'jYYYY-jMM-jDD').format("YYYY-MM-DDTHH:mm:ss.SSSS") : null,
      "page": data.page,
      "pageSize": data.pageSize
    };
    try {
      const response = yield Api.Post(Endpoints.GetPaymentList, body, true);

      if (response) {
        yield put({
          type: types.GET_PAYMENT_LIST_SUCCESS,
          data: { data: response, count: response.payments.totalItemsCount , skip : data.skip ,page:data.page },

        });
      } else
        yield put({
          type: types.GET_PAYMENT_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_PAYMENT_LIST_FAIL,
      });
    }
  });
}

export function* MakeInvoice() {

  yield takeEvery(types.MAKE_INVOICE, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      "billingComputationUnit": data.computationUnitCount,
      "billingPackagePrice": data.price,
      "description": "string"
    }
    try {
      const response = yield Api.Post(Endpoints.MakeInvoice, body, true);
      if (response) {
        yield put({
          type: types.MAKE_INVOICE_SUCCESS,
          data: response
        });
        //  data.callBack() ;
        yield put({
          type: types.DO_PAYMENT,
          data: { invoiceId: response, callBack: data.callBack }
        });
      } else
        yield put({
          type: types.MAKE_INVOICE_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.MAKE_INVOICE_FAIL,
      });
    }
  });
}


export function* DoPayment() {
  yield takeEvery(types.DO_PAYMENT, function* ({ data }) {
    let response = [];
    const direction = yield select((state) => state.App.direction);
    try {
      response = yield Api.Get(Endpoints.DoPayment + `?invoiceId=${data.invoiceId}`, true, "", true);
      if (response) {
        window.location.href = response;
        data?.callBack();
        yield put({
          type: types.DO_PAYMENT_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.DO_PAYMENT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.DO_PAYMENT_FAIL,
      });
    }
  });
}
export default function* PaymentSagas() {
  yield all(
    [
      fork(GetPriceList),
      fork(AddComputationUnit),
      fork(GetCu),
      fork(GetPaymentList),
      fork(MakeInvoice),
      fork(DoPayment)
    ]);
}