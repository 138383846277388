import {
  all,
  takeEvery,
  put,
  fork,
  select,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";


export function* ParticipateInSurvey() {
  yield takeEvery(types.PARTICIPATE_IN_SURVEY, function* ({ data }) {
    let body = {

    };
    try {
      const response = yield Api.Post(Endpoints.participateInSurvey, body, true);

      if (response) {
        yield put({
          type: types.PARTICIPATE_IN_SURVEY_SUCCESS,
          data: { data: response, count: response.wallets.totalItemsCount , skip : data.skip ,page:data.page },
        });
      } else
        yield put({
          type: types.PARTICIPATE_IN_SURVEY_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.PARTICIPATE_IN_SURVEY_FAIL,
      });
    }
  });
}


export default function* WalletSagas() {
  yield all(
    [
      fork(ParticipateInSurvey),

    ]);
}