import styled from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";


export const RegisterFormContainer = styled(Row)`
*{direction: ${(props) => props.direction=== "rtl" ? "rtl" : "ltr"};} 
    height :75vh;
    width: 80%;
    border-radius: ${(props) => props.theme.borderRadius};
    #login-container{
        height:100%; 
    }

`;
export const SideContainer = styled(Column)`
    color: white;
    text-align: justify;
    line-height: 38px;
    font-size: ${(props) => props.theme.fontSizeLg};
`;

////////////////////////////////////
export const FormContainer = styled(Row)`
   *{direction: ${(props) => props.direction=== "rtl" ? "rtl" : "ltr"};} 
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    form{
        width :100%;
        padding-top:20px;
    }
    .form-label{
        margin-bottom:10px;
        font-family: ${(props) => (props.theme.fontBold)};
    }

    ////////////////////////////////input
    .input-container {
      position: relative;
      width: 100%; /* Adjust width as needed */
      margin: 3px auto; /* Adjust margin as needed */
    }
    
    .input-container input[type="text"] ,.input-container input[type="password"]{
      width: 100%;
      padding: 10px; /* Adjust padding to accommodate icon */
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
      box-sizing: border-box;
      direction :${(props) => props.direction};
    }
    
    .input-container .icon {
      position: absolute;
    
      left : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
      right : ${(props) => props.direction === "ltr" ? "10px" : "unset"};
    
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    
    .input-container .placeholder-text {
      position: absolute;
      right : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
      left : ${(props) => props.direction === "ltr" ? "10px" : "unset"};
      top: 50%;
      transform: translateY(-50%);
      color: #999;
    }

`;

