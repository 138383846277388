import React from "react";

function Icon({pathFill ="AFAEAE"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
      fill={pathFill}
    >
      <g data-name="7. Wallet">
        <path fill={pathFill} d="M30 15.18V11a3 3 0 00-3-3h-1.5L19.8.4a1 1 0 00-1.39-.21l-5.06 3.69A6 6 0 003 8a3 3 0 00-3 3v18a3 3 0 003 3h24a3 3 0 003-3v-4.18A3 3 0 0032 22v-4a3 3 0 00-2-2.82zM18.79 2.39L23 8H11.08zM9 4a4 4 0 012.71 1.06L7.67 8H5a4 4 0 014-4zm18 26H3a1 1 0 01-1-1V11a1 1 0 011-1h24a1 1 0 011 1v4h-3a5 5 0 000 10h3v4a1 1 0 01-1 1zm3-8a1 1 0 01-1 1h-4a3 3 0 010-6h4a1 1 0 011 1z"></path>
        <path fill={pathFill} d="M25 19a1 1 0 101 1 1 1 0 00-1-1zM5 12a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM5 18a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM5 24a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z"></path>
      </g>
    </svg>
  );
}

export default Icon;