import styled from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
  padding : 10px 20px;
  ////////////////////////////////input
.input-container {
  position: relative;
  width: 100%; /* Adjust width as needed */
  margin: 3px auto; /* Adjust margin as needed */
}

.input-container input[type="text"] ,.input-container input[type="password"]{
  width: 100%;
  padding: 10px; /* Adjust padding to accommodate icon */
  padding-right : ${(props) => props.direction === "rtl" ? "100px" : "unset"};
  padding-left : ${(props) => props.direction === "ltr" ? "100px" : "unset"};
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  direction :${(props) => props.direction};
}

.input-container .icon {
  position: absolute;

  left : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
  right : ${(props) => props.direction === "ltr" ? "10px" : "unset"};

  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.input-container .placeholder-text {
  position: absolute;
  right : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
  left : ${(props) => props.direction === "ltr" ? "10px" : "unset"};
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}
  border-top :1px solid #ccc;
  border-bottom :1px solid #ccc;

`;

export const ExitContainer = styled(Row)`
padding :20px;

`;
