import React , { useEffect }from "react";
import HyperledgerBesuView from "src/views/dedicatedNetwork/hyperledgerBesu";
import MainLayout from "src/shared/MainLayout";
import { useDispatch } from "react-redux";

const hyperledgerBesu = () => {
    const dispatch = useDispatch();
    useEffect(() => {

    },[])
    return (
        <MainLayout>
            <HyperledgerBesuView />
        </MainLayout>
    )
}
export default hyperledgerBesu