import React from "react";
import { CardContainer } from "src/shared/generalStyles";
import { HyperLedgerFabricContainer } from "./style";
import Organization from "./components/organization/index";
import Peer from "./components/peer/index";
import Channel from "./components/channel/index"
import { useSelector } from "react-redux";

const hyperledgerFabric = () => {
  const hyperState = useSelector((state) => state.HyperledgerFabric);
  const { HyperledgerFabricStep } = hyperState;

  const StepTarget = (s) => {
    switch (s) {
      case 1:
        return <Organization />;
      case 2:
        return <Peer />;
      case 3:
         return <Channel />;
      default:
        return <Organization />;
        break;
    }
  };

  return (
    <CardContainer>
      <HyperLedgerFabricContainer>
        {StepTarget(HyperledgerFabricStep)}
      </HyperLedgerFabricContainer>
    </CardContainer>
  )
}
export default hyperledgerFabric
