import React , { useEffect }from "react";
import HyperledgerFabricView from "src/views/dedicatedNetwork/hyperledgerFebric";
import MainLayout from "src/shared/MainLayout";
import { useDispatch ,useSelector } from "react-redux";
import HyperLedgerActions from "src/redux/HyperledgerFabric/Actions";


const HyperledgerFabric = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);

    useEffect(() => {
        dispatch(HyperLedgerActions.getHyperLedgerList());
        dispatch(HyperLedgerActions.resetHyperFabricState());
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }

    },[])
    return (
        <MainLayout>
            <HyperledgerFabricView />
        </MainLayout>
    )
}
export default HyperledgerFabric