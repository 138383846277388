import React from "react";

function Icon({pathFill ="AFAEAE"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      className="icon"
      viewBox="0 0 1024 1024"
   
    >
      <path  fill={pathFill} d="M352.33 79.287c35.473 0 64.145 28.526 64.145 63.854v190.902a64 64 0 01-64.146 63.854H180.955a63.927 63.927 0 01-64.073-63.854V143.141c0-35.255 28.672-63.854 64.073-63.854H352.33zm0 510.244c35.473 0 64.145 28.526 64.145 63.854v190.903a64 64 0 01-64.146 63.854H180.955a63.927 63.927 0 01-64.073-63.854V653.385c0-35.328 28.672-63.854 64.073-63.854H352.33zM843.044 79.287c35.4 0 64.073 28.526 64.073 63.854v190.902a64 64 0 01-64.073 63.854H671.67a63.927 63.927 0 01-64.146-63.854V143.141c0-35.255 28.745-63.854 64.146-63.854h171.374zM173.056 458.898h187.173c64.512 0 116.736-51.931 116.736-116.077V134.363A116.297 116.297 0 00360.229 18.286h-187.1c-64.512 0-116.809 51.931-116.809 116.15v208.385a116.297 116.297 0 00116.736 116.077zm0 510.245h187.173c64.512 0 116.736-51.932 116.736-116.151V644.608A116.297 116.297 0 00360.229 528.53h-187.1c-64.512 0-116.736 51.932-116.736 116.078v208.457A116.297 116.297 0 00173.13 969.143zm490.789-510.245H850.87c64.512 0 116.736-51.931 116.736-116.077V134.363A116.297 116.297 0 00850.87 18.286h-187.1c-64.512 0-116.662 51.931-116.662 116.15v208.385a116.297 116.297 0 00116.736 116.077zm178.98 448.878H663.698c-38.62 0-55.296-16.091-55.296-54.784V644.608c0-38.546 16.75-55.37 55.37-55.37H850.87c38.766 0 56.174 16.75 56.174 55.297v208.603c0 12.8 13.677 27.502 31.524 27.502s29.038-14.702 29.038-27.502V644.535A116.297 116.297 0 00850.87 528.53h-187.1c-64.512 0-116.736 51.932-116.736 116.078v208.457a116.297 116.297 0 00116.663 116.078h186.588c13.458 0 30.72-10.972 30.72-30.793a30.72 30.72 0 00-30.135-30.574h-7.973z"></path>
    </svg>
  );
}

export default Icon;
