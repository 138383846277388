export default {
    SET_SHOW_WALLET_DETAIL_MODAL: 'SET_SHOW_WALLET_DETAIL_MODAL',

    PARTICIPATE_IN_SURVEY: 'PARTICIPATE_IN_SURVEY',
    PARTICIPATE_IN_SURVEY_SUCCESS: 'PARTICIPATE_IN_SURVEY_SUCCESS',
    PARTICIPATE_IN_SURVEY_FAIL: 'PARTICIPATE_IN_SURVEY_FAIL',
    PARTICIPATE_IN_SURVEY_EXCEPTION: 'PARTICIPATE_IN_SURVEY_EXCEPTION',
  
    SET_SHOW_CONFIRMATION_MODAL : "SET_SHOW_CONFIRMATION_MODAL",
    SET_SHOW_REJECT_MODAL :"SET_SHOW_REJECT_MODAL"

   
  };