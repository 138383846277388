import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import routePaths from "./nameSpace";
import { PrivateRoute } from "./privateRoute";
import Login from "src/pages/login/index";
import { useSelector } from "react-redux";
import Dashboard from "src/pages/dashboard/index";
import FirstLogin from "src/pages/firstLogin/index";
import EndpointPoint from "src/pages/endpointPoint/index";
import Wallet from "src/pages/wallet/index";
import Auth from "src/views/auth";
import PaymentHistory from "src/pages/payment/paymentHistory";
import Purchase from "src/pages/payment/purchase";
import RegisterComplete from "src/views/registerComplete";
import HyperledgerBesu from "src/pages/dedicatedNetwork/hyperledgerBesu";
import HyperledgerFabric from "src/pages/dedicatedNetwork/hyperledgerFabric";
import Survey from "src/pages/survey"


const AppRoute = () => {
  const isLoggedin = useSelector((state) => state.Login.isLoggedin);
  const firstLoggedin = useSelector((state) => state.Login.firstLoggedin);


  return (
    <BrowserRouter>
      <Switch>
        {/* <Route
          exact
          path={routePaths.login}
          component={(props) =>
            isLoggedin && firstLoggedin ? (
              <Redirect to={routePaths.completeInfo} />
            ) : isLoggedin && firstLoggedin === false ? (
              <Redirect to={routePaths.dashboard} />
            )
              : (
                <Login {...props} />
              )
          }
        /> */}
        <Route
          exact
          path={routePaths.login}
          component={(props) =>
            isLoggedin ? (
              <Redirect to={routePaths.dashboard} />
            ) : (
              <Login {...props} />
            )
          }
        />
        <Route
          component={() => <RegisterComplete />}
          path={routePaths.completeRegister}

        />
        <Route
          component={() => <Auth />}
          path={routePaths.auth}

        />
        <PrivateRoute
          path={routePaths.dashboard}
          component={() => <Dashboard />}
        />
        <PrivateRoute
          path={routePaths.completeInfo}
          component={() => <FirstLogin />}
        />
        <PrivateRoute
          path={routePaths.endpoint}
          component={() => <EndpointPoint />}
        />
        <PrivateRoute
          path={routePaths.wallet}
          component={() => <Wallet />}
        />
        <PrivateRoute
          path={routePaths.paymentHistory}
          component={() => <PaymentHistory />}
        />
        <PrivateRoute
          path={routePaths.purchase}
          component={() => <Purchase />}
        />
        <PrivateRoute
          path={routePaths.hyperledgerBesu}
          component={() => <HyperledgerBesu />}
        />
        <PrivateRoute
          path={routePaths.hyperledgerFabric}
          component={() => <HyperledgerFabric />}
        />
        <PrivateRoute
          path={routePaths.survey}
          component={() => <Survey />}
        />
      </Switch>
    </BrowserRouter>
  )
};
export default AppRoute;