import React from 'react';
import { useSelector } from 'react-redux';

function DeleteIcon() {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4713 16.1753C18.1744 16.9077 18.1744 18.0942 17.4713 18.8267C17.1225 19.1929 16.6612 19.3745 16.2 19.3745C15.7387 19.3745 15.2786 19.1914 14.9276 18.8252L9 12.6538L3.07294 18.8237C2.72137 19.1929 2.26069 19.3745 1.8 19.3745C1.33931 19.3745 0.879187 19.1929 0.527344 18.8237C-0.175781 18.0913 -0.175781 16.9048 0.527344 16.1724L6.45609 9.99658L0.527344 3.82373C-0.175781 3.09131 -0.175781 1.90479 0.527344 1.17236C1.23047 0.439941 2.36953 0.439941 3.07266 1.17236L9 7.35107L14.9287 1.17529C15.6319 0.442871 16.7709 0.442871 17.4741 1.17529C18.1772 1.90772 18.1772 3.09424 17.4741 3.82666L11.5453 10.0024L17.4713 16.1753Z" fill="#E43F32" />
        </svg>
    );
}

export default DeleteIcon;