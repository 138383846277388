import styled from "styled-components";
import Row from "../../kit/Row";

export const FooterWrapper = styled(Row)`
  width: 100%;
  height :30px;
  background-color:  ${(props) => (props.theme.primaryLight)} ;
  padding :0 20px;
  box-shadow: 0px 4px 16px 0px #00000070;
  box-sizing: border-box;
  z-index:11;
  a{
    padding:0 3px;
    font-family: ${(props) => (props.theme.fontBold)};

  }
`;
