import styled  from "styled-components";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
padding : 10px 20px;
*{direction : ${props => props.direction};}

`;

