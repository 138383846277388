import * as Yup from "yup";
import i18n from 'i18next'; 

const requireMessage = i18n.t("requiredMessage");
const TheEmailFormatIsNotCorrect = i18n.t("TheEmailFormatIsNotCorrect");

const emailRegex = "/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim"

export const string = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
};


export const email = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .matches(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim ,  TheEmailFormatIsNotCorrect)
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
}


export const number = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.number()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
};

export const boolean = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.boolean()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.boolean().label(label).nullable(true);
    }
};


