

import React, { useState } from "react";
import WalletActions from "src/redux/wallet/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, CardContainer, AddContainer } from "src/shared/generalStyles";
import Table from "src/shared/table";
import WalletTableConfig from "./WalletTableConfig";
import WalletDetailModal from "./WalletDetailModal";
import { FilterContainer } from "./styles";
import WalletFilter from "./WalletFilter"
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const Wallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const walletState = useSelector((state) => state.Wallet);
  const { takeWallet, skipWallet,WalletList, walletLoading, showWalletDetailModal, count, mode } = walletState;

  const defaultfields = {
    id: "",
    blockchainId: "",
    networkId:  "",
    exportable: "",
    label: "",
    type:  "1",
    Mnemonic:  "",
    privateKeyHex:  "",
    chainCodeHex:  "",
    kmsKeyId: "",
    parenWalletId: "",
  };
  const [selected, setSelected] = useState(1);
  const [fields, setFields] = useState(defaultfields);
  const [filterState, setFilterState] = useState({
    title: "",
    Wallet_position: "",
    is_active: "",
  });

  const addOrUpdateWalletsHandler = (values, action) => {
    let callBack = () => {
      setFields({
        ...fields,
        ...defaultfields,
      });
      setSelected(1);
    };
    if (mode === "add") {
      dispatch(
        WalletActions.addWallet({
          fields: values,
          callBack
        })
      );
    } else if (mode === "edit") {
      dispatch(
        WalletActions.updateWallet({
          fields: values,
          callBack
        })
      );
    }
  };

  const closeWalletsDetailsModal = (formik) => {
    formik.resetForm({});
    dispatch(
      WalletActions.setShowWalletDetailModal({
        visible: false,
        mode: "add",
      })
    );
    setFields({
      ...fields,
      ...defaultfields,
    });
    setSelected(1);
    formik.resetForm({});
  };
  //////////////////////////////////////
  const pageHandler = (res) => {
    dispatch(
      WalletActions.getWallet({
        skip: res,
        take: takeWallet,
        title: filterState.title,
        pageSize : takeWallet,
        page :(res+takeWallet)/takeWallet
      })
    );
  }

  return (
    <>
      <CardContainer>
      <FilterContainer>
          <Grid container>
            <Grid item md={10} className="filter-box">
              <WalletFilter filterState={filterState} setFilterState={setFilterState} />
            </Grid>

            <Grid item md={2} >
              <AddContainer>
                <span onClick={() => {
                  dispatch(
                    WalletActions.setShowWalletDetailModal({
                      visible: true,
                      mode: "add",
                    })
                  );
                }}>
                  {t("newWallet")}
                </span>
              </AddContainer>
            </Grid>
          </Grid>

        </FilterContainer>
        <TableWrapper height="calc(100% - 60px)">
          <Table
            tblConfig={WalletTableConfig()}
            tblData={WalletList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skipWallet}
            take={takeWallet}
            count={count}
            loading={walletLoading}
          />
        </TableWrapper>
      </CardContainer>
      <WalletDetailModal
        show={showWalletDetailModal}
        title={
          mode === "add" ? t("addWallet") :  t("walletDetail")
        }
        onclose={closeWalletsDetailsModal}
        onConfirm={addOrUpdateWalletsHandler}
        fields={fields}
        setFields={setFields}
        selected ={selected}
        setSelected ={setSelected}
      />
    </>
  )
}

export default Wallet;