import React , { useEffect }from "react";
import PaymentHistoryView from "src/views/payment/paymentHistory";
import MainLayout from "src/shared/MainLayout";
import { useDispatch ,useSelector } from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";



const PaymentHistory = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);
 
    useEffect(() => {
        dispatch(PaymentActions.getPaymentList({startDateTo :null , startDateFrom :null ,page:1,pageSize :5 ,skip:0}));
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }
    },[])
    return (
        <MainLayout>
            <PaymentHistoryView />
        </MainLayout>
    )
}
export default PaymentHistory