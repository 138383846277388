export default {

    GET_HYPER_LEDGER_lIST: 'GET_HYPER_LEDGER_lIST',
    GET_HYPER_LEDGER_lIST_SUCCESS: 'GET_HYPER_LEDGER_lIST_SUCCESS',
    GET_HYPER_LEDGER_lIST_FAIL: 'GET_HYPER_LEDGER_lIST_FAIL',
    GET_HYPER_LEDGER_lIST_EXCEPTION: 'GET_HYPER_LEDGER_lIST_EXCEPTION',

    SET_ORGANIZATION_LIST: 'SET_ORGANIZATION_LIST',
    SET_NEXT_HYPERLEGER_FEBRIC_STEP: 'SET_NEXT_HYPERLEGER_FEBRIC_STEP',
    SET_ORGANIZATIONS_PEERS_LIST :'SET_ORGANIZATIONS_PEERS_LIST',
    SET_ORGANIZATIONS_INFO :'SET_ORGANIZATIONS_INFO',

    GET_HYPER_LEDGER_DETAILS: 'GET_HYPER_LEDGER_DETAILS',
    GET_HYPER_LEDGER_DETAILS_SUCCESS: 'GET_HYPER_LEDGER_DETAILS_SUCCESS',
    GET_HYPER_LEDGER_DETAILS_FAIL: 'GET_HYPER_LEDGER_DETAILS_FAIL',
    GET_HYPER_LEDGER_DETAILS_EXCEPTION: 'GET_HYPER_LEDGER_DETAILS_EXCEPTION',

    ADD_HYPER_LEDGER: 'ADD_HYPER_LEDGER',
    ADD_HYPER_LEDGER_SUCCESS: 'ADD_HYPER_LEDGER_SUCCESS',
    ADD_HYPER_LEDGER_FAIL: 'ADD_HYPER_LEDGER_FAIL',
    ADD_HYPER_LEDGER_EXCEPTION: 'ADD_HYPER_LEDGER_EXCEPTION',

    SET_ORDERER_INFO: 'SET_ORDERER_INFO',
    SET_ORGANIZATION_PEERS_OPTIONS: 'SET_ORGANIZATION_PEERS_OPTIONS',
    RESET_HYPER_FABRIC_STATE : 'RESET_HYPER_FABRIC_STATE'

  };