import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import Button from "src/kit/Button"
import ConfirmationIcon from "src/assets/icons/ConfirmationIcon"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const ConfirmationModal = ({
    open,
    text,
    onClose,
}) => {

    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        <ConfirmationIcon />
                    </Grid>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        {text}
                    </Grid>
                </Grid>
                <Grid item container xs={12} p={1}>
                    <Grid item xs={6} p={1} >
                        <Button
                            onConfirm={() => { onClose(false) }}
                            text="بله"
                        >
                        </Button>
                    </Grid>
                    <Grid item xs={6} p={1}  id="reject_btn" >
                        <Button
                            onConfirm={() => { onClose(false) }}
                            text="خیر"
                        >
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
export default ConfirmationModal;